import React, { Component } from 'react'
import { configuration } from "../../../appConfig";
import Axios from "axios";
import "./loginpage.css";
import "react-table/react-table.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setLang } from "./setLang";

export class ChangePassword extends Component {
    state = {
        partnerId: "",
        username: "",
        oldpassword: "",
        newpassword: "",
        confirmnewpassword: "",
        nav1: null,
        nav2: null,
        errors: [],
      };

      handleSubmit = (e) => {
        e.preventDefault();
        const { partnerId, username, oldpassword, newpassword, confirmnewpassword } = this.state;
        let errors = []

        if (newpassword !== confirmnewpassword) {
            errors.push("Passwords do not match.");
        }

        if(errors.length){
            this.setState({
              errors : errors,
            })
          }
          else{
            this.setState({
              errors : [],
            })

            let payload = {
                partnerId: partnerId,
                email: username,
                old_password: oldpassword,
                new_password: newpassword,
                confirm_password: confirmnewpassword
            }
            Axios.post(configuration.changePassword ,payload)
            .then((res) => {
                if (res.data.status === 200) {
                    localStorage.setItem("partnerId", this.state.partnerId);
                    localStorage.setItem("pdfHeader", res.data.pdfHeader);
                    localStorage.setItem("pdfFooter", res.data.pdfFooter);

                    localStorage.setItem("logOutAlready",true)
                    if(this.state.partnerId === '002-sa') {
                    localStorage.setItem("impersonateUser",true)
                    }
                    this.getFallbackDetails();
                }else {
                    alert("Change Password Failed!,Try Again");
                }
               
            })
            .catch((err)=> {
            })
        }
      };

      onchange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };
    
      componentWillUnmount(){
        document.getElementById("body").classList.remove("login-page-body");
      }
    
      componentDidMount() {
        const partnerId = localStorage.getItem("partnerId");
        if(partnerId) {
          this.props.history.push("/dashboard");
        }
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2
        });
        document.getElementById("body").classList.add("login-page-body");
      }

      getTemplates = (client) => {
        let client_new = client+`${"_HAIL"}`
        let url = `${configuration.getTemplates}`+ "?client=" + client_new
        Axios.get(url)
        .then((res) => {
          if(res){
            localStorage.setItem("lang", res.data.lang[0]);
            localStorage.setItem("languages", res.data.lang);
          }
        })
        .catch((err) => {
        })
      }
      getFallbackDetails = async () => {
        const url =
          configuration.getFallbackDetails +
          `?partner_id=${localStorage.getItem("partnerId")}`;
          const headers = {
            'X-Auth-Token': localStorage.getItem('partnerId')
          }
          await Axios.get(url,
            // {headers}
          )
          .then((res) => {
            if(res.data.default_lang == null || res.data.default_lang == ''){
              this.getTemplates(res.data.client)
            } else{
                localStorage.setItem("lang", res.data.default_lang);
              }
            // localStorage.setItem("lang", res.data.default_lang);
            localStorage.setItem("client", res.data.client.includes("_") ? res.data.client.split("_")[0] : res.data.client);
            localStorage.setItem("partnerId", res.data.parent_partnerId ? res.data.parent_partnerId : res.data.licno);
            localStorage.setItem("partnerId_new", res.data.parent_partnerId ? res.data.licno : res.data.parent_partnerId);
          }).then(() => {
            this.props.history.push("/dashboard");
          })
          .catch((err) => {
          });
      };
      render() {
        const {modal, partnerId} = this.state
        return (
            <>
          <section>
                <div className="container-fluid">
                    <div className="row main-background-row">
                        <div className="col-lg-8 main-background-col">
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-10 ">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
                                        <ol className="carousel-indicators">
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"></li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12"></div>
                                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                                        <img src={require('./image/slide-1.png')} className="text-center" alt="slide-1" />
                                                    </div>
                                                </div>
                                                <div className="row equal row-1">
                                                    <div className="col-lg-1 col-md-12 col-sm-12 d-flex"></div>
                                                    <div className="col-lg-11 col-md-12 col-sm-12 d-flex">
                                                        <div className="card d-flex">
                                                            <div className="card-title text-heading text-slider">Automated reporting of vehicle condition for OEMs</div>
                                                            <div className="card-text text-paragraph">
                                                                The driveNscan scanner gives assurance in the recording and documenting of a vehicle’s condition for the new and used car trade as well as for vehicles of workshop customers.
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6  col-6">  
                                                                    <a target="_blank" href="https://adi-gmbh.com/drivenscan/"> 
                                                                    <button type="button" className="btn  learn-more">Learn More
                                                                    </button></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-12 col-sm-12 col-12"></div>
                                                    <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                                        <img src={require('./image/slide-2.png')}  className="d-block  text-center" alt="image-2" />
                                                    </div>
                                                </div>
                                                <div className="row equal  row-1">
                                                    <div className="col-lg-1 col-md-12 col-sm-12 d-flex"></div>
                                                    <div className="col-lg-11 col-md-12 col-sm-12 d-flex">
                                                        <div className="card d-flex">
                                                            <div className="card-title text-heading">Automated reporting of vehicle condition for logistics companies</div>
                                                            <div className="card-text text-paragraph">
                                                                The fully automated reporting of a vehicle's actual condition safeguards transfer risks, e.g. at reloading points.
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6  col-6">   
                                                                    <a target="_blank" href="https://adi-gmbh.com/drivenscan/">  <button type="button" className="btn  learn-more">Learn More
                                                                    </button></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12"></div>
                                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                                        <img src={require('./image/slide-4.png')}  className="d-block  text-center" alt="image-3" />
                                                    </div>
                                                </div>
                                                <div className="row row-1 equal">
                                                    <div className="col-lg-1 col-md-12 col-sm-12 d-flex"></div>
                                                    <div className="col-lg-11 col-md-12 col-sm-12 d-flex">
                                                        <div className="card d-flex">
                                                            <div className="card-title text-heading">Automated hail damage assessment for vehicle insurers</div>
                                                            <div className="card-text text-paragraph">
                                                                The driveNscan scanner provides fully automatic and high-precision reporting of hail damage to enable efficient damage processing in mass inspections.
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6  col-6">  
                                                                    <a target="_blank" href="https://adi-gmbh.com/drivenscan/">  <button type="button" className="btn  learn-more">Learn More
                                                                    </button></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-12 col-sm-12 col-12 col-12"></div>
                                                    <div className="col-lg-9 col-md-12 col-sm-12 col-12 col-12">
                                                        <img src={require('./image/slide-1.png')}  className="d-block  text-center" alt="image-3" />
                                                    </div>
                                                </div>
                                                <div className="row row-1 equal">
                                                    <div className="col-lg-1 col-md-12 col-sm-12 d-flex"></div>
                                                    <div className="col-lg-11 col-md-12 col-sm-12 d-flex">
                                                        <div className="card d-flex">
                                                            <div className="card-title text-heading text-slider">Automated reporting of vehicle condition for OEMs</div>
                                                            <div className="card-text text-paragraph">
                                                                Fully automated reporting of a vehicle's actual condition enables efficient quality control and safeguards transfer risks.
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6  col-6"> 
                                                                    <a target="_blank" href="https://adi-gmbh.com/drivenscan/">  <button type="button" className="btn  learn-more">Learn More
                                                                    </button></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-12 col-sm-12"></div>
                                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                                        <img src={require('./image/slide-3.png')}  className="d-block w-90 text-center" alt="image-3" />
                                                    </div>
                                                </div>
                                                <div className="row equal row-1">
                                                    <div className="col-lg-1 col-md-12 col-sm-12 d-flex"></div>
                                                    <div className="col-lg-11 col-md-12 col-sm-12 d-flex">
                                                        <div className="card d-flex">
                                                            <div className="card-title text-heading text-slider">Automated damage recording for fleet operators</div>
                                                            <div className="card-text text-paragraph">
                                                                Fast, flexible, efficient. The adomea scanner is ideal for automatically detecting damage in large company-fleets.
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6  col-6">  
                                                                    <a target="_blank" href="https://adi-gmbh.com/drivenscan/">  <button type="button" className="btn  learn-more">Learn More
                                                                    </button></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="row mt-4 bottom-btn-list text-center mt-sm-2 mt-lg-4">
                                    <div className="col-lg-1 col-sm-12 col-12"></div>
                                    <div className="col-lg-11 col-sm-12 col-12 d-none d-lg-block">
                                        <ul className=" buttom-ul-li">
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active">OEMs</li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1">Logistics</li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2">Hail Damage</li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"> Leasing</li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4">Rental</li>
                                        </ul>
                                    </div>
                                </div>
    
    
                            <div className="row  bottom-btn-list-small-device bottom-btn-list d-block d-lg-none">
                                    <div className="col-12">
                                        <ul className=" buttom-ul-li">
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active">OMEs</li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1">Logistics</li>
                                        </ul>
                                        <ul className=" buttom-ul-li text-center sm-large-ul">
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2">Hail Damage</li>
                                        </ul>
                                        <ul className=" buttom-ul-li">
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"> Leasing</li>
                                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4">Rental</li>
                                        </ul>
                                    </div>
                                </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 text-center col">
                            <div className="login-card">
                                <div className="card-body">
                                    <img src={require('./image/logo.png')} alt="Logo" />
                                    <h2 className="login-card-title">Change Password</h2>
                                    <p className="text-muted">Change your account Password</p>
                                    <form onSubmit={this.handleSubmit} method="post" action="">
                                    <div className="form-group">
                                            <label for="email" className="sr-only"></label>
                                            <input tabindex="1" className="form-control" placeholder="Partner Id" type="text" required onChange={this.onchange} name="partnerId" autoComplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="email" className="sr-only"></label>
                                            <input tabindex="2" className="form-control" placeholder="Username" name="username" required onChange={this.onchange} type="text"  autoComplete="on"/>
                                        </div>
                                        <div className="form-group">
                                            <label for="oldpassword" className="sr-only"></label>
                                            <input tabindex="1" className="form-control" placeholder="Old Password" type="password" name="oldpassword" onChange={this.onchange} autoComplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="newpassword" className="sr-only"></label>
                                            <input tabindex="2" className="form-control" placeholder="New Password" type="password" name="newpassword" onChange={this.onchange} autoComplete="on" />
                                        </div>
                                        <div className="form-group">
                                            <label for="confirmnewpassword" className="sr-only"></label>
                                            <input tabindex="3" className="form-control" placeholder="Confirm New Password" type="password" name="confirmnewpassword" onChange={this.onchange} autoComplete="on" />
                                            {this.state.errors.includes('Passwords do not match.') && <span style={{color : "red"}}>{this.lang("Passwords do not match.")}</span>}
                                        </div>
                                        <button color="primary"  tabindex="4"  name="login"  className="form-control login-btn fill"> 
                                        Change Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </>
        );
      }
}

export default ChangePassword
